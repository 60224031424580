<template>
  <div>
    <NotConnectAccount
      v-if="!connected"
      channel="Facebook"
      @handleConnect="handleConnect"
    />
    <div v-else>
      <div
        v-if="isActiveBtnNew"
        style="flex: 1;"
        class="mb-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="primary"
          @click="addNewFBLive()"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Add new') }}</span>
        </b-button>
      </div>
      <b-table
        striped
        hover
        responsive
        borderless
        :items="items"
        :fields="fields"
      >
        <template #cell(no)="item">
          {{ item.index + 1 }}
        </template>
        <template #cell(more)="item">
          <div class="text-center d-flex">
            <div
              class="text-danger"
              @click="handlerDelete(item.item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <!-- <b-tabs
      v-if="connected"
      content-class="pt-1"
    >
      <NotSyncProduct
        :list="notSyncProductList"
        channel="shopee"
      />
      <SyncProduct
        :list="syncProductList"
        channel="shopee"
      />
    </b-tabs> -->
  </div>
</template>

<script>
/* eslint-disable object-shorthand */
import {
// BTabs,
  BTable,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotConnectAccount from './components/NotConnectAccount.vue'
// import NotSyncProduct from './components/NotSyncProduct.vue'
// import SyncProduct from './components/SyncProduct.vue'

export default {
  components: {
    // BTabs,
    NotConnectAccount,
    // NotSyncProduct,
    // SyncProduct,
    BTable,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      token: null,
      isActiveBtnNew: true,
      currentChannel: null,
      connected: false,
      syncProductList: [],
      notSyncProductList: [],
      categories: [],
      isShow: false,
      fields: [
        { key: 'id', label: 'ID', sortable: false },
        { key: 'stream_url', label: 'Stream', sortable: false },
        { key: 'more', label: 'Công cụ', sortable: false },
      ],
      items: [],
    }
  },
  mounted() {
    this.loadSiteInfo()
  },
  methods: {
    async handlerDelete(id) {
      try {
        const params = {}
        params.id = id
        params.site_id = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/facebook/delete_live_facebook`,
          params,
        )
        if (res.data.status === true) {
          await this.handlerUpdate(this.token, 1)
          this.loadSiteInfo()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async addNewFBLive() {
      try {
        this.isActiveBtnNew = false
        const params = {}
        params.site_id = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/facebook/create_live_facebook`,
          params,
        )
        let data = null
        if (res.data.status === true) {
          const temp = {}
          temp.id = res.data.data.id
          temp.stream_url = res.data.data.stream_url
          this.items.push(temp)
          data = res.data.data
          await this.handlerUpdate(data, 2)
          this.isActiveBtnNew = false
        } else if (res.data.error.code === 190) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Session has expired',
            },
          })
          const token = null
          this.handlerUpdate(token, 3)
        } else if (res.data.error.code === 368) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'An error occurred, please try again later, or contact admin for help!',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            // this.model = res.data.data
            let url = null
            if (res.data.data.token_live_facebook !== '' && res.data.data.token_live_facebook !== null && res.data.data.token_live_facebook !== 'undefined') {
              this.connected = true
              if (JSON.parse(res.data.data.token_live_facebook).token) {
                this.token = JSON.parse(res.data.data.token_live_facebook).token
                // console.log(this.token)
              }
              if (JSON.parse(res.data.data.token_live_facebook).data) {
                // console.log(JSON.parse(res.data.data.token_live_facebook))
                this.isActiveBtnNew = false
                this.items = []
                const temp = {}
                temp.id = JSON.parse(res.data.data.token_live_facebook).data.id
                temp.stream_url = JSON.parse(res.data.data.token_live_facebook).data.stream_url
                this.items.push(temp)
              } else {
                this.isActiveBtnNew = true
                this.items = []
              }
            } else if (this.$router.currentRoute.hash !== '') {
              // console.log('hash', this.$router.currentRoute.hash)
              const urlHash = this.$router.currentRoute.hash.split('&')
              url = urlHash[0].split('=')
              await this.handlerUpdateToken(url[1], 1)
            }
            // this.loadSyncProduct()
            // this.loadShopeeProduct(res.data.data.id)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerUpdate(token, id) {
      try {
        // const params = {}
        const params = {
          token_live_facebook: {
          },
        }
        if (id === 1) {
          // params.token_live_facebook.token = token
          params.token_live_facebook = `{"token":"${token}"}`
        }
        if (id === 2) {
          const item = JSON.stringify(token)
          params.token_live_facebook = `{"token":"${this.token}","data":${item}}`
        }
        if (id === 3) {
          params.token_live_facebook = ''
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.connected = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Great',
                icon: 'AlertOctagonIcon',
                variant: 'success',
                text: 'Successful!',
              },
            })
          } else if (res.data.error.code === 190) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: 'Session has expired',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerUpdateToken(token, id) {
      try {
        // const params = {}
        const params = {
          token_live_facebook: {
          },
        }
        if (id === 1) {
          // params.token_live_facebook.token = token
          params.token_live_facebook = `{"token":"${token}"}`
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.connected = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Great',
                icon: 'AlertOctagonIcon',
                variant: 'success',
                text: 'Successful!',
              },
            })
            this.$router.push('/sales-channel')
          } else if (res.data.error.code === 190) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: 'Session has expired',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleConnect() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/facebook/get_oauth?type=1&redirect_uri=${window.location.origin}/channel/facebook-live`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            window.location.href = res.data.data.link
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleCategory(siteID) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_categories?site_id=${siteID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categories = res.data.data
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadShopeeProduct(siteID) {
      this.isShow = true
      try {
        await this.handleCategory(siteID)
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_item_list?site_id=${siteID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.map(async val => {
              const findIndex = this.syncProductList.findIndex(x => Number(x.code_e_commerce) === val.item_id)
              if (findIndex < 0) {
                const resDetail = await Request.get(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/shopee/get_item_detail/${val.item_id}?site_id=${siteID}`,
                )
                if (resDetail.status === 200) {
                  if (resDetail.data.status) {
                    const item = {
                      id: val.item_id,
                      avatar: resDetail.data.data.images[0],
                      name: resDetail.data.data.name,
                      sku: resDetail.data.data.item_sku,
                      category_id: resDetail.data.data.category_id,
                      category_name: this.categories.find(x => x.category_id === resDetail.data.data.category_id).category_name,
                      stock: resDetail.data.data.stock,
                    }
                    this.notSyncProductList.push(item)
                  }
                }
              }
            })
          }
        }
        this.isShow = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
    async loadSyncProduct() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&e_commerce=1`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.syncProductList = await this.getDataTranslation(res.items, true)
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
