<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <b-row>
    <b-col md="12">
      <div class="bg-gray mb-2">
        <h2>{{ channel }} account</h2>
        <div class="text-muted">
          Get started by connecting your exisiting {{ channel }} account or registering a new account.
        </div>
      </div>
      <div class="bg-gray mb-2">
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <b>No account connected</b>
            <div class="text-muted d-block mt-1">
              Your {{ channel }} sales channel has not been activated. Please activate to start using {{ channel }} on Buso platform.
            </div>
          </div>
          <b-button
            variant="primary"
            class="btn-icon rounded"
            @click="handleConnect"
          >
            Connect
          </b-button>
        </div>
      </div>
    </b-col></b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    channel: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleConnect() {
      this.$emit('handleConnect')
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
  padding: 16px 18px;
  border-radius: 6px;
}
</style>
