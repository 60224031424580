<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <b-row>
    <b-col md="3">
      <Fieldset id="salesChannel" title="Sales Channel">
        <SaleChannels />
      </Fieldset>
    </b-col>
    <b-col md="9">
      <Fieldset v-if="$route.params.id === 'shopee'" id="shopee" title="Shopee">
        <Shopee />
      </Fieldset>
      <Fieldset v-if="$route.params.id === 'lazada'" id="lazada" title="Lazada">
        <Lazada />
      </Fieldset>
      <Fieldset v-if="$route.params.id === 'facebook-live'" id="facebook" title="Facebook live">
        <Facebook />
      </Fieldset>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable object-shorthand */
/* eslint-disable global-require */
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import SaleChannels from './components/SaleChannels.vue'
import Shopee from './shopee.vue'
import Lazada from './lazada.vue'
import Facebook from './facebook.vue'

export default {
  components: {
    BRow,
    BCol,
    SaleChannels,
    Shopee,
    Lazada,
    Facebook,
  },
}
</script>
