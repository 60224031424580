<template>
  <div>
    <NotConnectAccount
      v-if="!connected"
      channel="Shopee"
      @handleConnect="handleConnect"
    />
    <b-tabs
      v-if="connected"
      content-class="pt-1"
    >
      <NotSyncProduct
        :list="notSyncProductList"
        channel="shopee"
      />
      <SyncProduct
        :list="syncProductList"
        channel="shopee"
      />
    </b-tabs>
  </div>
</template>

<script>
/* eslint-disable object-shorthand */
import {
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotConnectAccount from './components/NotConnectAccount.vue'
import NotSyncProduct from './components/NotSyncProduct.vue'
import SyncProduct from './components/SyncProduct.vue'

export default {
  components: {
    BTabs,
    NotConnectAccount,
    NotSyncProduct,
    SyncProduct,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      currentChannel: null,
      connected: false,
      syncProductList: [],
      notSyncProductList: [],
      categories: [],
      isShow: false,
    }
  },
  mounted() {
    this.loadSiteInfo()
  },
  methods: {
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            // this.model = res.data.data
            if (res.data.data.shop_id_shopee !== '' && res.data.data.shop_id_shopee !== null && res.data.data.shop_id_shopee !== undefined) {
              this.connected = true
            } else if (this.$route.query.shop_id) {
              await this.handlerUpdate(this.$route.query.shop_id)
            }
            this.loadSyncProduct()
            this.loadShopeeProduct(res.data.data.id)
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerUpdate(shopID) {
      try {
        const params = {
          // name: this.model.name,
          is_active: 1,
          shop_id_shopee: shopID,
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.connected = true
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleConnect() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/auth_partner?mode=production&path=${window.location.origin}/channel/shopee`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            window.location.href = res.data.data.link
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleCategory(siteID) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_categories?site_id=${siteID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categories = res.data.data
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadShopeeProduct(siteID) {
      this.isShow = true
      try {
        await this.handleCategory(siteID)
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_item_list?site_id=${siteID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.map(async val => {
              const findIndex = this.syncProductList.findIndex(x => Number(x.code_e_commerce) === val.item_id)
              if (findIndex < 0) {
                const resDetail = await Request.get(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/shopee/get_item_detail/${val.item_id}?site_id=${siteID}`,
                )
                if (resDetail.status === 200) {
                  if (resDetail.data.status) {
                    const item = {
                      id: val.item_id,
                      avatar: resDetail.data.data.images[0],
                      name: resDetail.data.data.name,
                      sku: resDetail.data.data.item_sku,
                      category_id: resDetail.data.data.category_id,
                      category_name: this.categories.find(x => x.category_id === resDetail.data.data.category_id).category_name,
                      stock: resDetail.data.data.stock,
                    }
                    this.notSyncProductList.push(item)
                  }
                }
              }
            })
          }
        }
        this.isShow = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
    async loadSyncProduct() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&e_commerce=1`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.syncProductList = await this.getDataTranslation(res.items, true)
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
